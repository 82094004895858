import React, {useContext, useEffect, useState} from 'react'
import { DarkModeContext } from '../../context/DarkModeContext'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import logo from "../../assets/img/logo.png";
import BienvenidoAdmin from "../../components/administracion/BienvenidoAdmin";
import TituloPagina from "../../components/TituloPagina";
import TablaReporte from '../../components/administracion/TablaReporte'

const ReporteMensual = () => {
    const {darkMode}= useContext(DarkModeContext)
    const apiUrlToken=process.env.REACT_APP_API_TOKEN
    const navegacion= useNavigate()

    useEffect(() => {
        const verificarToken = async () => {
          const token = sessionStorage.getItem("token");
    
          if (!token) {
            manejarRespuestaNoAutorizada();
            return;
          }
    
          try {
            const response = await fetch(apiUrlToken, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ Token: token }),
            });
    
            if (response.ok) {
              const data = await response.json();
    
              if (data !== 8) {
                manejarAccesoDenegado();
              }
            } else {
              if (response.status === 401) {
                manejarRespuestaNoAutorizada();
              } else {
                throw new Error("Respuesta no satisfactoria del servidor");
              }
            }
          } catch (error) {
            console.error("Error al validar el token", error);
          }
        };
    
        const redireccionar = () => {
          navegacion("/");
          recargarPagina();
        };
    
        const manejarRespuestaNoAutorizada = () => {
          sessionStorage.removeItem("token"); 
    
          Swal.fire({
            title: "Sesión expirada o token inválido.",
            text: "Inicie sesión nuevamente.",
            imageUrl: logo,
            imageWidth: 100,
            imageHeight: 30,
            imageAlt: "Logo",
            confirmButtonText: "Ok",
            timer: 5000,
            allowOutsideClick: true,
            customClass: {
              container: darkMode
                ? "swal2-container--dark"
                : "swal2-container--light",
              confirmButton: "my-swal-button",
            },
          }).then(redireccionar);
        };
    
        const manejarAccesoDenegado = () => {
          Swal.fire({
            title: "Acceso denegado.",
            text: "No tiene permisos para acceder.",
            imageUrl: logo,
            imageWidth: 100,
            imageHeight: 30,
            imageAlt: "Logo",
            confirmButtonText: "Ok",
            timer: 5000,
            allowOutsideClick: true,
            customClass: {
              container: darkMode
                ? "swal2-container--dark"
                : "swal2-container--light",
              confirmButton: "my-swal-button",
            },
          }).then(redireccionar);
        };
    
        verificarToken();
      }, []);

      function recargarPagina() {
        window.location.reload()
      }


    return (
    <div className="d-flex flex-column min-vh-100" translate="no">
        <div className="pt-5">
        <BienvenidoAdmin />
      </div>
      <div>
        <TituloPagina title="Panel de administracion" />
      </div>
      <div>
        <TablaReporte/>
      </div>

      <div className="py-4 mt-auto">
            <Footer />
        </div>
    </div>
  )
}

export default ReporteMensual