import React, { useContext } from 'react';
import { Pie } from 'react-chartjs-2';
import styles from './grafTerminal.css'; // Importa los estilos
import { DarkModeContext } from '../context/DarkModeContext';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Importar el plugin
import Chart from 'chart.js/auto'; // Importar Chart de chart.js para registrar plugins

// Chart.register(ChartDataLabels); // Registrar el plugin


const GrafTerminal = ({ data }) => {
    console.log(data); // Esto debería mostrar el objeto completo y confirmar las claves
    const darkMode= useContext(DarkModeContext)

    const chartData = {
        labels: [
            `Débito: ${data.porcentajesDebito}%`, 
            `Crédito: ${data.porcentajesCredito}%`, 
            `Un Pago: ${data.porcentajes1Pago}%`, 
            `Sin Facturación: ${data.porcentajeSinFacturacion}%`
        ],
        datasets: [{
            data: [
                data.porcentajesDebito, 
                data.porcentajesCredito, 
                data.porcentajes1Pago, 
                data.porcentajeSinFacturacion
            ],
            backgroundColor: ['#4CAF50', '#FF6384', '#FFC107', '#D3D3D3'],
            hoverBackgroundColor: ['#45A049', '#FF4264', '#FFB300', '#C0C0C0']
        }],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '60%',  
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            },
            tooltip: {
                callbacks: {
                    label: function(tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw || 0;
                        return `${label}: ${value.toFixed(2)}%`;
                    }
                }
            },
            datalabels: {
                color: 'black',
                textAlign: 'center',
                font: {
                    weight: 'bold',
                    size: 16
                },
                formatter: (value, ctx) => {
                    if (ctx.dataIndex === 0) { // Asume que el primer segmento es 'Con Facturación'
                        return `Terminales en uso\n${data.porcentajeConFacturacion}%`;
                    } else {
                        return ''; // No muestra nada en los otros segmentos
                    }
                },
                display: true    // Asegúrate de que los datalabels estén habilitados
        }
    }
};

    

    return (
        <div style={{ paddingTop: "0px", height: "100%" }}
        className={
          darkMode ? "bg-grafica px-5 pb-4"  : "bg-grafica-dark px-5 pb-4"
        }> {/* Usando la clase del archivo CSS */}
            <Pie data={chartData} key={`${data.conFacturacion}-${data.sinFacturacion}-${data.totalComercios}-${data.porcentajesSinFacturacion}-${data.porcentajesonFacturacion}`}   options={options} />
        </div>
    );
};

export default GrafTerminal;