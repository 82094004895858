import React, {useContext} from 'react'
import { Bar } from 'react-chartjs-2';
import styles from './grafTerminal.css'; // Importa los estilos
import { DarkModeContext } from '../context/DarkModeContext';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Importar el plugin
import Chart from 'chart.js/auto'; // Importar Chart de chart.js para registrar plugins

const GraficoTerminales = (data) => {
  console.log(data); // Esto debería mostrar el objeto completo y confirmar las claves
  const darkMode= useContext(DarkModeContext)

  const chartData = {
    labels: ['Con Facturación', 'Sin Facturación'],
    datasets: [
      {
        label: 'Terminales',
        data: [data.conFacturacion, data.sinFacturacion],  // 1300 y 300 respectivamente
        backgroundColor: ['#4CAF50', '#D3D3D3'],
        hoverBackgroundColor: ['#45A049', '#C0C0C0'],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,  // Asegura que el eje Y comience desde cero
        ticks: {
          stepSize: 100,  // Ajusta el tamaño del paso en el eje Y
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      datalabels: {
        color: 'black',
        font: {
          weight: 'bold',
          size: 16,
        },
        display: true,
        formatter: (value) => value,  // Muestra el valor directamente en la barra
      },
    },
  };

  

  return (
      <div style={{ paddingTop: "0px", height: "100%" }}
      className={
        darkMode ? "bg-grafica px-5 pb-4"  : "bg-grafica-dark px-5 pb-4"
      }> {/* Usando la clase del archivo CSS */}
          <Bar data={chartData} key={`${data.conFacturacion}-${data.sinFacturacion}-${data.totalComercios}`}   options={options} />
      </div>
  );
};


export default GraficoTerminales