import React, { useContext, useState, useEffect } from "react";
import ItemTablaLegajos from "./ItemTablaLegajos";
import { Button, Form } from "react-bootstrap";
import { DarkModeContext } from "../../context/DarkModeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import ModalSolicitudNuevoLegajo from "./ModalSolicitudNuevoLegajo";

const TablaLegajos = () => {
    const { darkMode } = useContext(DarkModeContext);
    const [modalShowCompleta, setModalShowCompleta] = useState(false);
    const [legajos, setLegajos] = useState([]);

    useEffect(() => {
        const fetchLegajos = async () => {
            try {
                const response = await fetch("/api/legajo/listacompleto"); // Reemplaza con tu endpoint real
                const data = await response.json();
                setLegajos(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchLegajos();
    }, []);

    // Obtener los últimos 10 legajos
    const ultimos10Legajos = legajos.slice(-10).reverse(); // Toma los últimos 10 y revierte el orden

    return (
        <div
            className={
                darkMode
                    ? "container bg-tabla-usuarios-liquidaciones-dark"
                    : "container bg-tabla-usuarios-liquidaciones"
            }
        >
            <section className="container mt-3 mb-3">
                <div className="d-flex flex-wrap justify-content-between">
                    <div className="margin-centrado-responsive">
                        <div className="my-3 d-flex">
                            <h6 className="my-3 me-3 fs-18-a-16">Filtrar: </h6>
                            <div className="campo-busqueda">
                                <Form.Group
                                    className="d-flex justify-content-center"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Control
                                        className={
                                            darkMode
                                                ? "form-control text-white label-buscador-dark lato-regular fs-18 border-0"
                                                : "form-control label-buscador lato-regular fs-18 border-0"
                                        }
                                        maxLength={11}
                                        type="text"
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        required
                                        placeholder="CUIT"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex centrado-responsive pt-3">
                        <article className="d-flex justify-content-end">
                            <Button
                                className={
                                    darkMode
                                        ? "centrado border-0 btn btn-modal-nuevo-usuario-editar centrado border-0 mx-2"
                                        : "centrado  border-0 btn btn-modal-nuevo-usuario-editar centrado border-0 mx-2"
                                }
                                onClick={() => setModalShowCompleta(true)}
                            >
                                <FontAwesomeIcon icon={faCirclePlus} />{" "}
                                <span className="lato-bold fs-18 ms-2"> Nuevo</span>
                            </Button>
                        </article>
                        <ModalSolicitudNuevoLegajo
                            show={modalShowCompleta}
                            onHide={() => setModalShowCompleta(false)}
                        />
                    </div>
                </div>
            </section>
            <article>
                <div
                    className={
                        darkMode
                            ? "container table-responsive py-3 px-5"
                            : "container table-responsive py-3 px-5"
                    }
                >
                    <table className="table table-borderless responsive striped hover">
                        <thead className="border-0">
                            <tr className="text-center tabla-thead">
                                <th
                                    className={
                                        darkMode
                                            ? "bg-white text-dark border-tabla-izquierda border-0 lato-regular fs-12 py-3"
                                            : "bg-dark text-white border-tabla-izquierda border-0 lato-regular fs-12 py-3"
                                    }
                                    scope="col"
                                >
                                    ID
                                </th>
                                <th
                                    className={
                                        darkMode
                                            ? "bg-white text-dark border-0 lato-regular fs-12 py-3"
                                            : "bg-dark text-white border-0 lato-regular fs-12 py-3"
                                    }
                                    scope="col"
                                >
                                    Nro Legajo
                                </th>
                                <th
                                    className={
                                        darkMode
                                            ? "bg-white text-dark border-0 lato-regular fs-12 py-3"
                                            : "bg-dark text-white border-0 lato-regular fs-12 py-3"
                                    }
                                    scope="col"
                                >
                                    Terminal
                                </th>
                                <th
                                    className={
                                        darkMode
                                            ? "bg-white text-dark border-0 lato-regular fs-12 py-3"
                                            : "bg-dark text-white fs-12 lato-regular py-3"
                                    }
                                    scope="col"
                                >
                                    CUIT
                                </th>
                                <th
                                    className={
                                        darkMode
                                            ? "bg-white text-dark border-0 lato-regular fs-12 py-3"
                                            : "bg-dark text-white fs-12 lato-regular py-3"
                                    }
                                    scope="col"
                                >
                                    Nombre de fantasía
                                </th>
                                <th
                                    className={
                                        darkMode
                                            ? "bg-white text-dark border-0 lato-regular fs-12 py-3"
                                            : "bg-dark text-white fs-12 lato-regular py-3"
                                    }
                                    scope="col"
                                >
                                    Razón social
                                </th>
                                <th
                                    className={
                                        darkMode
                                            ? "bg-white text-dark border-0 lato-regular fs-12 py-3"
                                            : "bg-dark text-white fs-12 lato-regular py-3"
                                    }
                                    scope="col"
                                >
                                    Provincia
                                </th>
                                <th
                                    className={
                                        darkMode
                                            ? "bg-white text-dark border-0 lato-regular fs-12 py-3"
                                            : "bg-dark text-white fs-12 lato-regular py-3"
                                    }
                                    scope="col"
                                >
                                    Fecha de carga
                                </th>
                                <th
                                    className={
                                        darkMode
                                            ? "bg-white text-dark border-0 border-tabla-derecha lato-regular fs-12 py-3"
                                            : "bg-dark text-white fs-12 lato-regular py-3 border-tabla-derecha"
                                    }
                                    scope="col"
                                >
                                    Asesor - Gestor
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {ultimos10Legajos.length > 0 ? (
                                ultimos10Legajos.map((dato, id) => (
                                    <ItemTablaLegajos key={id} dato={dato} darkMode={darkMode} />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="9" className="lato-bold fs-12-a-10">
                                        No se encontraron resultados para esta búsqueda.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </article>
        </div>
    );
};

export default TablaLegajos;
