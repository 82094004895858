import React, { useState, useEffect, useContext } from "react";
import { DarkModeContext } from "../../context/DarkModeContext";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

const TablaCRMInterno = () => {
  const [datos, setDatos] = useState([]); // Estado para los datos del CRM
  const [fecha, setFecha] = useState(new Date().toISOString().split("T")[0]); // Estado para la fecha (por defecto hoy)
  const [paginaActual, setPaginaActual] = useState(1); // Estado para la paginación
  const [totalPaginas, setTotalPaginas] = useState(1); // Estado para el total de páginas
  const registrosPorPagina = 10; // Número de registros por página
  const { darkMode } = useContext(DarkModeContext);


  useEffect(() => {
    // Función para obtener los datos desde la API
    const obtenerDatos = async (fechaSeleccionada, pagina) => {
      const token = sessionStorage.getItem("token");

      try {
        const response = await fetch("/api/calidad/listadias", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: token,
            Fecha: fechaSeleccionada,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          // Dividir los datos en páginas
          const inicio = (pagina - 1) * registrosPorPagina;
          const fin = inicio + registrosPorPagina;
          setDatos(data.slice(inicio, fin)); // Guardamos los primeros 10 resultados
          setTotalPaginas(Math.ceil(data.length / registrosPorPagina)); // Calculamos el total de páginas
        } else {
          console.error("Error al obtener los datos");
        }
      } catch (error) {
        console.error("Error en la conexión", error);
      }
    };

    obtenerDatos(fecha, paginaActual); // Llamada a la API por defecto al cargar el componente
  }, [fecha, paginaActual]); // Actualizar cuando la fecha o la página cambien

  // Manejar cambio de fecha
  const handleFechaChange = (e) => {
    setFecha(e.target.value);
    setPaginaActual(1); // Resetear a la primera página cuando se cambia la fecha
  };

  // Cambiar página
  const cambiarPagina = (nuevaPagina) => {
    if (nuevaPagina > 0 && nuevaPagina <= totalPaginas) {
      setPaginaActual(nuevaPagina);
    }
  };

  return (
    <section
      className={
        darkMode
          ? "container cuadro-calificaciones-dark"
          : "container cuadro-calificaciones"
      }
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        marginTop: "20px",
      }}
    >
      <div
        className={
          darkMode
            ? "container table-responsive py-3 px-5"
            : "container table-responsive py-3 px-5"
        }
      >
        {/* Selector de fecha */}

        {/* Tabla de resultados */}
        <article>
          <div
            className={
              darkMode
                ? "container table-responsive py-3 px-5"
                : "container table-responsive py-3 px-5"
            }
          >
            <div className="form-group" style={{
    display: "flex",
    alignItems: "left",
    marginBottom: "10px",
  }}>
    
              <label htmlFor="fecha" className="lato-regular fs-16" style={{
      display: "flex", // Para alinear icono y texto
      alignItems: "center", // Centra verticalmente el texto y el icono
      height: "38px", // Asegura que la altura sea igual al input
    }}> <FontAwesomeIcon icon={faCalendarDays} style={{marginRight:"5px", color:"#89939F"}} /> Fecha:</label>
              <input
                type="date"
                id="fecha"
                className="form-control"
                value={fecha}
                onChange={handleFechaChange}
                style={{
                    width: "auto", // Ajusta el tamaño del input a lo necesario
                    marginLeft: "5px",
                    height: "38px", // Muy poco espacio entre el label y el input
                    background:"#DCDEE1"
                  }}
              />
            </div>
            <Table table table-borderless responsive striped hov>
              <thead className="bg-dark py-2">
                <tr className="text-center tabla-thead">
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla-izquierda border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla-izquierda border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    ID
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Nombre Usuario
                  </th>{" "}
                  {/* Nueva columna para el nombre */}
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Fecha
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Hora
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Canal de Atención
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Tipo
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Aliado
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Motivo
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Estado
                  </th>
                  <th
                    className={
                      darkMode
                        ? "bg-white text-dark border-tabla-derecha border-0 lato-regular fs-14 py-3"
                        : "bg-dark text-white border-tabla-derecha border-0 lato-regular fs-14 py-3"
                    }
                    scope="col"
                  >
                    Observaciones
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {datos.length > 0 ? (
                  datos.map((dato, index) => (
                    <tr
                      className={
                        darkMode ? "bg-dark text-white" : "bg-white text-dark"
                      }
                      key={index}
                    >
                      <td
                        className={
                          darkMode
                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                            : "fs-14 lato-regular py-4 prority-4 "
                        }
                      >
                        {dato.id}
                      </td>
                      <td
                        className={
                          darkMode
                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                            : "fs-14 lato-regular py-4 prority-4 "
                        }
                      >
                        {dato.nombreUsuario}
                      </td>{" "}
                      {/* Mostrar el nombre del usuario */}
                      <td
                        className={
                          darkMode
                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                            : "fs-14 lato-regular py-4 prority-4 "
                        }
                      >
                        {new Date(dato.fechaCarga).toLocaleDateString()}
                      </td>
                      <td
                        className={
                          darkMode
                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                            : "fs-14 lato-regular py-4 prority-4 "
                        }
                      >
                        {new Date(dato.fechaCarga).toLocaleTimeString()}
                      </td>
                      <td
                        className={
                          darkMode
                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                            : "fs-14 lato-regular py-4 prority-4 "
                        }
                      >
                        {dato.canalAtencion}
                      </td>
                      <td
                        className={
                          darkMode
                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                            : "fs-14 lato-regular py-4 prority-4 "
                        }
                      >
                        {dato.tipo}
                      </td>
                      <td
                        className={
                          darkMode
                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                            : "fs-14 lato-regular py-4 prority-4 "
                        }
                      >
                        {dato.aliado}
                      </td>
                      <td
                        className={
                          darkMode
                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                            : "fs-14 lato-regular py-4 prority-4 "
                        }
                      >
                        {dato.motivo}
                      </td>
                      <td
                        className={
                          darkMode
                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                            : "fs-14 lato-regular py-4 prority-4 "
                        }
                      >
                        {dato.estado}
                      </td>
                      <td
                        className={
                          darkMode
                            ? "fs-14 lato-regular py-4 prority-4 text-white "
                            : "fs-14 lato-regular py-4 prority-4 "
                        }
                      >
                        {dato.observaciones}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9">
                      No hay datos disponibles para la fecha seleccionada.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </article>

        {/* Paginación */}
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-primary"
            onClick={() => cambiarPagina(paginaActual - 1)}
            disabled={paginaActual === 1}
          >
            Anterior
          </button>
          <span>
            Página {paginaActual} de {totalPaginas}
          </span>
          <button
            className="btn btn-primary"
            onClick={() => cambiarPagina(paginaActual + 1)}
            disabled={paginaActual === totalPaginas}
          >
            Siguiente
          </button>
        </div>
      </div>
    </section>
  );
};

export default TablaCRMInterno;
