import React, { useContext, useState, useEffect } from "react";
import {
  FormControl,
  InputGroup,
  Row,
  Col,
  Form,
  Button,
  Card,
  Spinner,
  Modal,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import Select from "react-select";
import { DarkModeContext } from "../../context/DarkModeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUndo,
  faEye,
  faEdit,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Swal from "sweetalert2";
import "./CRM.css";
const fieldMap = {
  id: "Id",
  sasmutual: "SasMutual",
  estado: "Estado",
  ndeterminal: "NDeTerminal",
  ndecomercio: "NDeComercio",
  nroLocal: "NroLocal",
  legajo: "Legajo",
  nombredefantasia: "NombreDeFantasia",
  domicilio: "Domicilio",
  localidad: "Localidad",
  codigoPostal: "CodigoPostal",
  telefono: "Telefono",
  linkdepago: "LinkDePago",
  rubro: "Rubro",
  rubrosecundario: "RubroSecundario",
  altaahorA12: "AltaAhora12",
  fechadealta: "FechaDeAlta",
  fechadebaja: "FechaDeBaja",
  tipodebaja: "TipoDeBaja",
  provincia: "Provincia",
  nombredelasesor: "NombreDelAsesor",
  alta: "Altas",
  baja: "Baja",
  mail: "Mail",
  titular: "Titular",
  cuitcuil: "CuitCuil",
  banco: "Banco",
  tipodecuenta: "TipoDeCuenta",
  cbucvu: "CbuCvu",
  ndecuenta: "NDeCuenta",
  letracbu: "LetraCbu",
  largocbu: "LargoCbu",
  alias: "Alias",
  razonsocial: "RazonSocial",
  cuitrazonsocial: "CuitRazonSocial",
  estadoafip: "EstadoAfip",
  codactividad: "CodActividad",
  actividad: "Actividad",
  exento: "Exento",
  estadorentas: "EstadoRentas",
  actividaD2: "Actividad2",
  domiciliofiscal: "DomicilioFiscal",
  provinciA3: "Provincia3",
  alicuota: "Alicuota",
  codactividadrentas: "CodActividadRentas",
  actividadrentas: "ActividadRentas",
};

const colorMap = {
  blue: [
    /* "Id",*/
    "SasMutual",
    "Estado",
    "NDeTerminal",
    "NDeComercio",
    "NroLocal",
    "Legajo",
    "NombreDeFantasia",
    "Domicilio",
    "Localidad",
    "CodigoPostal",
    "Telefono",
    "LinkDePago",
    "Rubro",
    "RubroSecundario",
    "AltaAhora12",
    "FechaDeAlta",
    "FechaDeBaja",
    "TipoDeBaja",
    "Provincia",
    "NombreDelAsesor",
    "Altas",
    "Baja",
    "Mail",
  ],
  violet: [
    "Titular",
    "CuitCuil",
    "Banco",
    "TipoDeCuenta",
    "CbuCvu",
    "NDeCuenta",
    "LetraCbu",
    "LargoCbu",
    "Alias",
  ],
  yellow: [
    "RazonSocial",
    "CuitRazonSocial",
    "EstadoAfip",
    "CodActividad",
    "Actividad",
    "Exento",
    "EstadoRentas",
    "Actividad2",
    "DomicilioFiscal",
    "Provincia3",
    "Alicuota",
    "CodActividadRentas",
    "ActividadRentas",
  ],
};

const getBackgroundColor = (field) => {
  const mappedField = fieldMap[field] || field;
  if (colorMap.blue.includes(mappedField)) return "#B4C400"; // Primer color
  if (colorMap.violet.includes(mappedField)) return "#E89F2F"; // Segundo color
  if (colorMap.yellow.includes(mappedField)) return "#EFCA39"; // Último color
  return "white";
};

const estadoOptions = [
  { value: "ALTA", label: "ALTA" },
  { value: "BAJA", label: "BAJA" },
  { value: "DISPONIBLE", label: "DISPONIBLE" },
  { value: "ACREDITACION PENDIENTE", label: "ACREDITACION PENDIENTE" },
  { value: "SERVICIO.TECNICO", label: "SERVICIO.TECNICO" },
  { value: "EXTRAVIADA", label: "EXTRAVIADA" },
];

const altaOptions = [
  { value: "AGREGA", label: "AGREGA" },
  { value: "ALTA CCTT", label: "ALTA CCTT" },
  { value: "ALTA NUEVA", label: "ALTA NUEVA" },
  { value: "ALTA SSTT", label: "ALTA SSTT" },
];

const bajaOptions = [
  { value: "BAJA", label: "BAJA" },
  { value: "BAJA POR FACTURACION", label: "BAJA POR FACTURACION" },
  { value: "BAJA POR POSNET", label: "BAJA POR POSNET" },
  { value: "BAJA POR POSTET C", label: "BAJA POR POSTET C" },
  { value: "BAJA SSTT", label: "BAJA SSTT" },
  { value: "VERIFONE REUTILIZABLE", label: "VERIFONE REUTILIZABLE" },
];

const getInitialFieldValues = () => {
  const initialValues = {};
  Object.keys(fieldMap).forEach((key) => {
    initialValues[key] = ""; // Asigna valores iniciales vacíos
  });
  return initialValues;
};

const CRM = ({ datos = [], view }) => {
  const [searchField, setSearchField] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchTerms, setSearchTerms] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const { darkMode } = useContext(DarkModeContext);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);

  const [showNewModal, setShowNewModal] = useState(false);
  const [terminalBaja, setTerminalBaja] = useState("");
  const [terminalAlta, setTerminalAlta] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [selectMotivo,setMotivo]=useState("")
  const [observacion, setObservacion] = useState("");
  const [tipoOperacion, setTipoOperacion] = useState("alta"); // Estado independiente para Tipo
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [legajos, setLegajos] = useState([]);
  const [comercios, setComercios] = useState([]);
  const [rubros, setRubros] = useState([]);
  const [altaAhora12Options] = useState([
    { value: "SI", label: "SI" },
    { value: "NO", label: "NO" },
  ]);
  const [linkDePagoOptions] = useState([
    { value: "SI", label: "SI" },
    { value: "NO", label: "NO" },
  ]);
  const [provincias, setProvincias] = useState([]);
  const [asesores, setAsesores] = useState([]);
  const [bancos, setBancos] = useState([]);
  const tipoCuentaOptions = [
    { value: "CAJA AHORRO", label: "CAJA AHORRO" },
    { value: "CUENTA CORRIENTE", label: "CUENTA CORRIENTE" },
    { value: "BILLETERA VIRTUAL", label: "BILLETERA VIRTUAL" },
  ];
  const formatDateInput = (value) => {
    const digitsOnly = value.replace(/\D/g, "");
    const charArray = digitsOnly.split("");
    if (charArray.length > 4) {
      charArray.splice(4, 0, "/");
    }
    if (charArray.length > 7) {
      charArray.splice(7, 0, "/");
    }
    return charArray.join("");
  };

  const handleDateChange = (field, value) => {
    const formattedValue = formatDateInput(value);
    setModalData((prevData) => ({ ...prevData, [field]: formattedValue }));
  };
  useEffect(() => {
    const selectedProvince = provincias.find(
      (option) => option.value === modalData.provincia3
    );
    if (selectedProvince) {
      setModalData((prevData) => ({
        ...prevData,
        alicuota: selectedProvince.alicuota,
      }));
    }
  }, [modalData.provincia3, provincias]);
  const getTerminalOptions = (estado, excludeEstado = false) => {
    return datos
      .filter((dato) =>
        excludeEstado ? dato.estado !== estado : dato.estado === estado
      )
      .map((dato) => ({ value: dato.ndeterminal, label: dato.ndeterminal }));
  };

  useEffect(() => {
    fetch("/api/legajo/lista")
      .then((response) => response.json())
      .then((data) => {
        setLegajos(
          data.map((item) => ({ value: item.nroLegajo, label: item.nroLegajo }))
        );
      });

    fetch("/api/Comercio/lista")
      .then((response) => response.json())
      .then((data) => {
        setComercios(
          data.map((item) => ({ value: item.num, label: item.numComercio }))
        );
      });

    fetch("/api/comercio/listarubros")
      .then((response) => response.json())
      .then((data) => {
        setRubros(data.map((item) => ({ value: item, label: item })));
      });

    fetch("/api/provincia/listaprovincia")
      .then((response) => response.json())
      .then((data) => {
        setProvincias(
          data.map((item) => ({
            value: item.provincia,
            label: item.provincia,
            alicuota: item.alicuota,
          }))
        );
      });

    fetch("/api/usuarios/obtenergestoresyasesores")
      .then((response) => response.json())
      .then((data) => {
        setAsesores(data.map((item) => ({ value: item, label: item })));
      });
    fetch("/api/Banco/lista")
      .then((response) => response.json())
      .then((data) => {
        setBancos(
          data.map((item) => ({ value: item.nomBanco, label: item.nomBanco }))
        );
      });
  }, []);

  const handleSelectorChange = (selectedOption) => {
    setSearchField(selectedOption.value);
    setSearchValue("");
  };

  const handleSearchSubmit = () => {
    if (searchField) {
      setLoading(true);
      setTimeout(() => {
        setSearchTerms({ field: searchField, value: searchValue });
        setShowAll(false);
        setLoading(false);
      }, 1000);
    }
  };

  const handleResetSearch = () => {
    setSearchField("");
    setSearchValue("");
    setSearchTerms(null);
    setShowAll(false);
  };

  const handleShowAll = () => {
    setLoading(true);
    setTimeout(() => {
      setSearchTerms(null);
      setShowAll(true);
      setLoading(false);
    }, 1000);
  };

  const filteredDatos = datos.filter((dato) => {
    if (!searchTerms) return false;

    const { field, value } = searchTerms;

    return (
      value === "" || (dato[field] && dato[field].toString().includes(value))
    );
  });

  const selectOptions = [
    { value: "ndeterminal", label: "N° Terminal" },
    { value: "ndecomercio", label: "N° Comercio" },
    { value: "cuitcuil", label: "CUIT CUIL" },
    { value: "cuitrazonsocial", label: "Cuit razón social" },
  ];

  const handleShowModal = (
    dato = getInitialFieldValues(),
    editMode = true,
    tipo = "alta"
  ) => {
    setModalData(dato); // Actualiza los datos del modal
    setIsEditMode(editMode);
    setTipoOperacion(tipo); // Establecer el tipo (alta o editar) de manera independiente
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalData({});
  };

  const handleInputChange = (field, value) => {
    setModalData((prevData) => ({
      ...prevData,
      [field]: value,
      Tipo: prevData.Tipo || "alta", // Mantén siempre el valor de 'Tipo'
    }));
  };

  const handleSaveChanges = async () => {
    try {
      setIsSubmitting(true);
      const token = sessionStorage.getItem("token");

      const dataToSend = { token };

      // Mapeamos todas las claves de modalData usando el fieldMap
      Object.keys(modalData).forEach((key) => {
        const mappedKey = fieldMap[key]; // Mapeamos cada clave
        if (mappedKey) {
          dataToSend[mappedKey] =
            modalData[key] !== null ? modalData[key].toString() : "";
        }
      });

      // Añadir el valor de 'Tipo' desde la variable tipoOperacion o un estado
      dataToSend.Tipo = tipoOperacion || "alta"; // Si 'tipoOperacion' no está definido, se usa "alta" como valor por defecto

      console.log("Data enviada:", dataToSend); // Verificar lo que se va a enviar

      // Determinar la URL según el tipo de operación
      const apiUrl =
        view === "alta"
          ? "/api/altas/editarcrmalta"
          : "/api/bajas/editarcrmbajas";

      // Enviar los datos mediante fetch
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      // Manejo de errores
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.status} - ${errorText}`);
      }

      setIsSubmitting(false);

      // Si todo está bien, cerrar el modal y mostrar mensaje de éxito
      handleCloseModal();
      Swal.fire({
        icon: "success",
        title: "Cambios guardados",
        text: "Los cambios han sido guardados exitosamente.",
      }).then(() => {
        window.location.reload();
      });
    } catch (err) {
      setIsSubmitting(false);
      console.error("Error al guardar los cambios:", err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Los cambios no pudieron ser guardados.",
      });
    }
  };

  const handleNewModalSubmit = async () => {
    setIsSubmitting(true);
    const apiMap = {
      option1: "/api/abm/baja",
      option2: "/api/abm/sstt",
      option3: "/api/abm/ssttcambio",
      option4: "/api/abm/bajaycambioclover",
      option5: "/api/abm/desvinculacion", // Nueva opción
      option6: "/api/abm/cambiotitularidad", // API para cambio de titularidad
    };

    const apiUrl = apiMap[selectValue];
    const token = sessionStorage.getItem("token");

    const dataToSend = {
      Token: token,
      Tipo: selectValue,
      Observacion: observacion,
      NumeroTerminalBaja: terminalBaja ? terminalBaja.toString() : "",
      NumeroTerminalAlta: terminalAlta ? terminalAlta.toString() : "",
      Motivo:selectMotivo
    };

    // Si es opción 6, agrega los datos de titularidad
    if (selectValue === "option6") {
      Object.assign(dataToSend, {
        Titular: modalData.Titular,
        CuitCuil: modalData.CuitCuil,
        Banco: modalData.Banco,
        TipoDeCuenta: modalData.TipoDeCuenta,
        CbuCvu: modalData.CbuCvu,
        NDeCuenta: modalData.NDeCuenta,
        LetraCbu: modalData.LetraCbu,
        LargoCbu: modalData.LargoCbu,
        Alias: modalData.Alias,
      });
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.status} - ${errorText}`);
      }

      setIsSubmitting(false);
      handleCloseNewModal();
      Swal.fire({
        icon: "success",
        title: "Datos guardados",
        text: "Los datos han sido guardados exitosamente.",
      }).then(() => {
        window.location.reload();
      });
    } catch (err) {
      setIsSubmitting(false);
      console.error("Error al enviar los datos:", err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Los datos no pudieron ser guardados.",
      });
    }
  };

  const handleCloseNewModal = () => {
    setShowNewModal(false);
    setTerminalBaja("");
    setTerminalAlta("");
    setSelectValue("");
    setObservacion("");
    setMotivo("")
  };

  const handleTerminalAltaChange = (selectedOption) => {
    if (selectedOption) {
      const terminal = datos.find(
        (datos) => datos.ndeterminal === selectedOption.value
      );
      if (terminal) {
        const newData = {};
        Object.keys(fieldMap).forEach((key) => {
          newData[key] = terminal[key] || "";
        });
        setModalData(newData);
      }
      setTerminalAlta(selectedOption.value);
    } else {
      setTerminalAlta("");
      setModalData(getInitialFieldValues());
    }
  };

  const hasLetters = (str) => {
    return /[a-zA-Z]/.test(str);
  };

  const renderField = (key) => {
    const displayName = fieldMap[key] || key;
    const value = modalData[key] || "";
    const commonProps = {
      key,
      controlId: key,
      className: "mb-3",
    };
    switch (key) {
      case "sasmutual":
      case "id":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control type="text" value={value} readOnly />
          </Form.Group>
        );
      case "ndeterminal":
        if (!isEditMode) {
          const terminalOptions = datos
            .filter((datos) => datos.estado !== "ALTA")
            .map((datos) => ({
              value: datos.ndeterminal,
              label: datos.ndeterminal,
            }));
          return (
            <Form.Group {...commonProps}>
              <Form.Label>{displayName}</Form.Label>
              <Select
                value={
                  terminalOptions.find((option) => option.value === value) ||
                  null
                }
                onChange={handleTerminalAltaChange}
                options={terminalOptions}
                isClearable
              />
            </Form.Group>
          );
        }
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control type="text" value={value} readOnly />
          </Form.Group>
        );
      case "estado":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            {isEditMode ? (
              <Form.Control
                readOnly
                value={
                  estadoOptions.find((option) => option.value === value)
                    ?.label || ""
                }
              />
            ) : (
              <Select
                value={estadoOptions.find((option) => option.value === value)}
                onChange={(selectedOption) =>
                  handleInputChange(key, selectedOption.value)
                }
                options={estadoOptions}
              />
            )}
          </Form.Group>
        );

      case "legajo":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={legajos.find((option) => option.value === value)}
              onChange={(selectedOption) =>
                handleInputChange(key, selectedOption.value)
              }
              options={legajos}
            />
          </Form.Group>
        );
      case "ndecomercio":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                comercios.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(key, selectedOption.value)
              }
              options={comercios}
            />
          </Form.Group>
        );
      case "nroLocal":
      case "codigoPostal":
      case "telefono":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              maxLength={key === "nrolocal" ? 4 : 15}
              pattern={
                key === "telefono" || key === "codigopostal"
                  ? "[0-9]*"
                  : undefined
              }
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "linkdepago":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={linkDePagoOptions.find((option) => option.value === value)}
              onChange={(selectedOption) =>
                handleInputChange(key, selectedOption.value)
              }
              options={linkDePagoOptions}
            />
          </Form.Group>
        );
      case "rubro":
      case "rubrosecundario":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={rubros.find((option) => option.value === value) || null}
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={rubros}
              isClearable
            />
          </Form.Group>
        );
      case "altaahorA12":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={altaAhora12Options.find(
                (option) => option.value === value
              )}
              onChange={(selectedOption) =>
                handleInputChange(key, selectedOption.value)
              }
              options={altaAhora12Options}
            />
          </Form.Group>
        );
      case "fechadealta":
      case "fechadebaja":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value || ""}
              onChange={(e) => handleDateChange(key, e.target.value)}
              placeholder="YYYY/MM/DD"
              maxLength={10}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "provincia":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                provincias.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={provincias}
              isClearable
            />
          </Form.Group>
        );
      case "nombredelasesor":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                asesores.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={asesores}
              isClearable
            />
          </Form.Group>
        );
      case "alta":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                altaOptions.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={altaOptions}
              isClearable
            />
          </Form.Group>
        );
      case "baja":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                bajaOptions.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={bajaOptions}
              isClearable
            />
          </Form.Group>
        );
      case "mail":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="email"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "cuitcuil":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "banco":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                bancos.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(key, selectedOption.value)
              }
              options={bancos}
              isClearable
            />
          </Form.Group>
        );
      case "tipodecuenta":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                tipoCuentaOptions.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) => {
                handleInputChange(key, selectedOption.value);
                handleInputChange(
                  "cbucvu",
                  selectedOption.value === "BILLETERA VIRTUAL" ? "CVU" : "CBU"
                );
              }}
              options={tipoCuentaOptions}
              isClearable
            />
          </Form.Group>
        );
      case "cbucvu":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              readOnly
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "ndecuenta":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              onChange={(e) => {
                const newValue = e.target.value;
                handleInputChange(key, newValue);
                handleInputChange(
                  "letracbu",
                  hasLetters(newValue) ? "CORRECTO" : "INCORRECTO"
                );
                handleInputChange("largocbu", newValue.length);
              }}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "letracbu":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              readOnly
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "largocbu":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              readOnly
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "cuitrazonsocial":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "estadoafip":
        const estadoAfipOptions = [
          { value: "EXENTO", label: "EXENTO" },
          { value: "INHABILITADO", label: "INHABILITADO" },
          { value: "MONOTRIBUTISTA", label: "MONOTRIBUTISTA" },
          { value: "RESPONSABLE INSCRIPTO", label: "RESPONSABLE INSCRIPTO" },
        ];
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                estadoAfipOptions.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={estadoAfipOptions}
              isClearable
            />
          </Form.Group>
        );
      case "codactividad":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "exento":
        const exentoOptions = [
          { value: "NO", label: "NO" },
          { value: "EXENTO GANANCIA", label: "EXENTO GANANCIA" },
        ];
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                exentoOptions.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={exentoOptions}
              isClearable
            />
          </Form.Group>
        );
      case "estadorentas":
        const estadoRentasOptions = [
          { value: "ALTA EN PROCESO", label: "ALTA EN PROCESO" },
          { value: "CM", label: "CM" },
          { value: "EXENTO", label: "EXENTO" },
          { value: "INSCRIPTO", label: "INSCRIPTO" },
          { value: "NO INSCRIPTO", label: "NO INSCRIPTO" },
        ];
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                estadoRentasOptions.find(
                  (option) => option.value === value
                ) || { value, label: value }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={estadoRentasOptions}
              isClearable
            />
          </Form.Group>
        );
      case "actividaD2":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "provinciA3":
        const selectedProvincia = provincias.find(
          (option) => option.value === value
        ) || { value, label: value };
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                isEditMode && selectedProvincia.value !== undefined
                  ? selectedProvincia
                  : { value, label: value }
              }
              onChange={(selectedOption) => {
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                );
                const selectedAlicuota =
                  provincias.find(
                    (option) => option.value === selectedOption?.value
                  )?.alicuota || "";
                handleInputChange("alicuota", selectedAlicuota);
              }}
              options={provincias}
              isClearable
            />
          </Form.Group>
        );

      case "alicuota":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              readOnly
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );

      case "codactividadrentas":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );

      default:
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              // style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
    }
  };

  return (
    <>
      <div className="container">
        <Row>
          <Col>
            <Form.Label className="fs-12 lato-bold">
              Seleccionar campo de búsqueda
            </Form.Label>
            <Select
              className="select__control_custom-inicio select__control_custom lato-bold fs-12"
              classNamePrefix="select"
              value={selectOptions.find(
                (option) => option.value === searchField
              )}
              onChange={handleSelectorChange}
              options={selectOptions}
              isSearchable={false}
            />
          </Col>
        </Row>
        {searchField && (
          <Row className="mt-3">
            <Col md={4}>
              <InputGroup className="mb-3 d-flex align-items-center">
                <FormControl
                  className="form-control input-ingresar-buscador-crm px-5 border-0"
                  placeholder={`Buscar por ${
                    selectOptions.find((option) => option.value === searchField)
                      .label
                  }`}
                  aria-label={`Buscar por ${searchField}`}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Button
                  className="btn-publicar border-0 mx-1"
                  onClick={handleSearchSubmit}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
                <Button
                  className="btn-publicar border-0 mx-1"
                  onClick={handleResetSearch}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <Button
                  className="btn-publicar border-0 mx-1"
                  onClick={handleShowAll}
                >
                  <FontAwesomeIcon icon={faEye} />
                </Button>
                <Button
                  className="btn-publicar border-0 mx-1"
                  onClick={() => handleShowModal({}, false, "alta")}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>

                <Button
                  className="btn-publicar border-0 mx-1"
                  onClick={() => setShowNewModal(true)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </InputGroup>
            </Col>
          </Row>
        )}
      </div>

      <section style={{ maxHeight: "500px", overflowY: "auto" }}>
        <div className="container">
          {loading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : !searchTerms && !showAll ? (
            <Card className={darkMode ? "my-3 text-black" : "my-3"}>
              <Card.Body
                className={
                  darkMode ? "bg-dark text-white border-0" : "text-dark"
                }
              >
                <Card.Text className="lato-bold fs-16">
                  <strong>Cómo realizar la búsqueda:</strong>
                  <br />
                  1. Utiliza el selector para elegir el campo que quieres
                  buscar: N° de terminal, N° de comercio, CUIT-CUIL, o CUIT
                  Razón Social.
                  <br />
                  2. Aparecerá un campo de búsqueda donde podrás ingresar el
                  valor que deseas buscar.
                  <br />
                  3. Escribe el valor en el campo de búsqueda y presiona el{" "}
                  <strong>botón con el ícono de lupa</strong>.<br />
                  4. Para ver todos los registros, presiona el{" "}
                  <strong>botón con el ícono de ojo</strong>.<br />
                  5. Para restablecer la búsqueda, presiona el{" "}
                  <strong>botón con el ícono de retorno</strong>.
                </Card.Text>
              </Card.Body>
            </Card>
          ) : (searchTerms && filteredDatos.length > 0) || showAll ? (
            (showAll ? datos : filteredDatos).map((dato, index) => (
              <Card
                key={index}
                className={`mb-3 ${
                  darkMode ? "bg-dark text-white" : "bg-light text-dark"
                }`}
              >
                <Card.Body>
                  <Row className="flex-nowrap align-items-stretch">
                    <Col md={3} className="d-flex flex-column">
                      <div className="d-flex flex-column flex-grow-1">
                        {Object.keys(dato)
                          .slice(0, Math.ceil(Object.keys(dato).length / 4))
                          .map((key, i) => {
                            const displayName =
                              fieldMap[key.toLowerCase()] || key;
                            return (
                              <div
                                key={i}
                                className="mb-2 p-2 flex-grow-1"
                                style={{
                                  backgroundColor: getBackgroundColor(key),
                                  borderRadius: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                {key.toLowerCase() === "id" && (
                                  <Row className="align-items-center">
                                    <Col>
                                      <strong>{displayName}:</strong>{" "}
                                      {dato[key]}
                                    </Col>
                                    <Col xs="auto">
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          handleShowModal(dato, true, "editar")
                                        }
                                        className="btn-publicar border-0"
                                        style={{
                                          padding: "0 10px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                    </Col>
                                  </Row>
                                )}
                                {key !== "id" && (
                                  <>
                                    <strong>{displayName}:</strong> {dato[key]}
                                  </>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                    <Col md={3} className="d-flex flex-column">
                      <div className="d-flex flex-column flex-grow-1">
                        {Object.keys(dato)
                          .slice(
                            Math.ceil(Object.keys(dato).length / 4),
                            Math.ceil((Object.keys(dato).length * 2) / 4)
                          )
                          .map((key, i) => {
                            const displayName = fieldMap[key] || key;
                            return (
                              <div
                                key={i}
                                className="mb-2 p-2 flex-grow-1"
                                style={{
                                  backgroundColor: getBackgroundColor(key),
                                  borderRadius: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <strong>{displayName}:</strong> {dato[key]}
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                    <Col md={3} className="d-flex flex-column">
                      <div className="d-flex flex-column flex-grow-1">
                        {Object.keys(dato)
                          .slice(
                            Math.ceil((Object.keys(dato).length * 2) / 4),
                            Math.ceil((Object.keys(dato).length * 3) / 4)
                          )
                          .map((key, i) => {
                            const displayName = fieldMap[key] || key;
                            return (
                              <div
                                key={i}
                                className="mb-2 p-2 flex-grow-1"
                                style={{
                                  backgroundColor: getBackgroundColor(key),
                                  borderRadius: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <strong>{displayName}:</strong> {dato[key]}
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                    <Col md={3} className="d-flex flex-column">
                      <div className="d-flex flex-column flex-grow-1">
                        {Object.keys(dato)
                          .slice(Math.ceil((Object.keys(dato).length * 3) / 4))
                          .map((key, i) => {
                            const displayName = fieldMap[key] || key;
                            return (
                              <div
                                key={i}
                                className="mb-2 p-2 flex-grow-1"
                                style={{
                                  backgroundColor: getBackgroundColor(key),
                                  borderRadius: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <strong>{displayName}:</strong> {dato[key]}
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))
          ) : (
            searchTerms &&
            filteredDatos.length === 0 && (
              <Card className="mb-3 text-center">
                <Card.Body>
                  <Card.Text>
                    No se encontraron resultados para la búsqueda.
                  </Card.Text>
                </Card.Body>
              </Card>
            )
          )}
        </div>
      </section>

      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? "Editar Datos" : "Agregar Alta"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={3}>
                {Object.keys(fieldMap)
                  .slice(0, Math.ceil(Object.keys(fieldMap).length / 4))
                  .map((key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: getBackgroundColor(key),
                        borderRadius: "8px",

                        textAlign: "left",
                      }}
                    >
                      {renderField(key)}
                    </div>
                  ))}
              </Col>
              <Col md={3}>
                {Object.keys(fieldMap)
                  .slice(
                    Math.ceil(Object.keys(fieldMap).length / 4),
                    Math.ceil((Object.keys(fieldMap).length * 2) / 4)
                  )
                  .map((key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: getBackgroundColor(key),
                        borderRadius: "8px",

                        textAlign: "left",
                      }}
                    >
                      {renderField(key)}
                    </div>
                  ))}
              </Col>
              <Col md={3}>
                {Object.keys(fieldMap)
                  .slice(
                    Math.ceil((Object.keys(fieldMap).length * 2) / 4),
                    Math.ceil((Object.keys(fieldMap).length * 3) / 4)
                  )
                  .map((key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: getBackgroundColor(key),
                        borderRadius: "8px",

                        textAlign: "left",
                      }}
                    >
                      {renderField(key)}
                    </div>
                  ))}
              </Col>
              <Col md={3}>
                {Object.keys(fieldMap)
                  .slice(Math.ceil((Object.keys(fieldMap).length * 3) / 4))
                  .map((key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: getBackgroundColor(key),
                        borderRadius: "8px",

                        textAlign: "left",
                      }}
                    >
                      {renderField(key)}
                    </div>
                  ))}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Spinner animation="border" size="sm" />
              </>
            ) : isEditMode ? (
              "Guardar Cambios"
            ) : (
              "Enviar"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showNewModal} onHide={handleCloseNewModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Gestionar Terminales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Terminal a dar de baja */}
            <Form.Group controlId="terminalBaja">
              <Form.Label>Terminal a dar de baja</Form.Label>
              <Select
                value={
                  getTerminalOptions("ALTA").find(
                    (option) => option.value === terminalBaja
                  ) || null
                }
                onChange={(option) =>
                  setTerminalBaja(option ? option.value : "")
                }
                options={getTerminalOptions("ALTA")}
                isClearable
              />
            </Form.Group>

            {/* Terminal a dar de alta */}
            <Form.Group controlId="terminalAlta">
              <Form.Label>Terminal a dar de alta</Form.Label>
              {selectValue === "option4" || selectValue === "option5" ? (
                <Form.Control
                  type="text"
                  value={terminalAlta}
                  onChange={(e) => setTerminalAlta(e.target.value)}
                />
              ) : (
                <Select
                  value={
                    selectValue === "option1" || selectValue === "option2"
                      ? null
                      : getTerminalOptions("ALTA", true).find(
                          (option) => option.value === terminalAlta
                        ) || null
                  }
                  onChange={(option) =>
                    setTerminalAlta(option ? option.value : "")
                  }
                  options={getTerminalOptions("ALTA", true)}
                  isClearable
                  isDisabled={
                    selectValue === "option1" || selectValue === "option2"
                  }
                />
              )}
            </Form.Group>

            {/* Observación */}
            <Form.Group controlId="observacion">
              <Form.Label>Observación</Form.Label>
              <Form.Control
                type="text"
                value={observacion}
                onChange={(e) => setObservacion(e.target.value)}
              />
            </Form.Group>

            {/* Acción a realizar */}
            <Form.Group controlId="selectOption">
              <Form.Label>Acción a realizar</Form.Label>
              <Form.Control
                as="select"
                value={selectValue || "option1"} // Preselecciona option1 por defecto
                onChange={(e) => {
                  setSelectValue(e.target.value);
                  if (e.target.value === "option1") {
                    setTerminalAlta("");
                  }
                }}
              >
                <option value="option1">Baja</option>
                <option value="option2">SSTT</option>
                <option value="option3">SSTT Cambio</option>
                <option value="option4">Baja y Cambio Clover</option>
                <option value="option5">Desvinculación</option>
                <option value="option6">Cambio Titularidad</option>{" "}
                {/* Nueva opción */}
              </Form.Control>
            </Form.Group>
            <FormGroup controlId="selectMotivo">
              <FormLabel>Motivo de Baja</FormLabel>
              <FormControl
                as="select"
                value={selectMotivo || ""} // Mantener el valor seleccionado
                onChange={(e) => setMotivo(e.target.value)} // Cambiar el valor al seleccionar una opción
              >
                {bajaOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormControl>
            </FormGroup>

            {/* Campos adicionales para opción 6: Cambio Titularidad */}
            {/* Campos adicionales para opción 6: Cambio Titularidad */}
            {selectValue === "option6" && (
              <>
                <Form.Group controlId="Titular">
                  <Form.Label>Titular</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.Titular}
                    onChange={(e) =>
                      handleInputChange("Titular", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="CuitCuil">
                  <Form.Label>CUIT / CUIL</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.CuitCuil}
                    onChange={(e) =>
                      handleInputChange("CuitCuil", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="Banco">
                  <Form.Label>Banco</Form.Label>
                  <Select
                    value={
                      bancos.find(
                        (option) => option.value === modalData.Banco
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange("Banco", selectedOption.value)
                    }
                    options={bancos}
                  />
                </Form.Group>

                <Form.Group controlId="TipoDeCuenta">
                  <Form.Label>Tipo de Cuenta</Form.Label>
                  <Select
                    value={
                      tipoCuentaOptions.find(
                        (option) => option.value === modalData.TipoDeCuenta
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      handleInputChange("TipoDeCuenta", selectedOption.value);
                      // Ajustar CBU o CVU dependiendo del tipo de cuenta
                      handleInputChange(
                        "CbuCvu",
                        selectedOption.value === "BILLETERA VIRTUAL"
                          ? "CVU"
                          : "CBU"
                      );
                    }}
                    options={tipoCuentaOptions}
                  />
                </Form.Group>

                <Form.Group controlId="CbuCvu">
                  <Form.Label>CBU / CVU</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.CbuCvu}
                    readOnly // Este campo será solo de lectura ya que depende del tipo de cuenta
                  />
                </Form.Group>

                <Form.Group controlId="NDeCuenta">
                  <Form.Label>Número de Cuenta</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.NDeCuenta}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      handleInputChange("NDeCuenta", newValue);
                      // Ajustar Letra y Largo del CBU en base al número de cuenta ingresado
                      handleInputChange(
                        "LetraCbu",
                        hasLetters(newValue) ? "CORRECTO" : "INCORRECTO"
                      );
                      handleInputChange("LargoCbu", newValue.length);
                    }}
                  />
                </Form.Group>

                <Form.Group controlId="LetraCbu">
                  <Form.Label>Letra CBU</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.LetraCbu}
                    readOnly // Solo lectura, ya que se ajusta automáticamente
                  />
                </Form.Group>

                <Form.Group controlId="LargoCbu">
                  <Form.Label>Largo CBU</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.LargoCbu}
                    readOnly // Solo lectura, ya que se ajusta automáticamente
                  />
                </Form.Group>

                <Form.Group controlId="Alias">
                  <Form.Label>Alias</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.Alias}
                    onChange={(e) => handleInputChange("Alias", e.target.value)}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNewModal}>
            Cerrar
          </Button>
          <Button
            variant="success"
            onClick={handleNewModalSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Spinner animation="border" size="sm"></Spinner>
              </>
            ) : (
              "Enviar"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CRM;
